import { Component } from '@angular/core';
import { HttpService } from './shared/services/http.service';
import { SettingService } from './shared/services/setting.service';
import { LocalStorageService } from './shared/services/webApis/local-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Lazy loading feature modules';
  constructor(private http: HttpService,
    private setting : SettingService,
    private sessionStorage : LocalStorageService
    ) { }

  ngOnInit() {
    this.getAllSettings();
  }
  
  getAllSettings() {
    this.http.getReq(`/setting`).subscribe(res => {
      this.sessionStorage.setItem("allSettings" , JSON.stringify(res));
    },
      err => {
      }
    );
  }
}
