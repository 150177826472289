import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService, SocialUser } from 'angularx-social-login';
import { HttpService } from './http.service';
import { LocalStorageService } from './webApis/local-storage.service';

interface ShowPassword {
  showPassword: boolean,
  passwordFieldType: string
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private localStorage: LocalStorageService,
    private httpService: HttpService,
    private router: Router) { }

  public isAuthenticated(): boolean {
    const token = this.getAuthorizationToken();
    if (token) {
      return true;
    } else {
      return false;
    }
  }

  getAuthorizationToken(): string {
    const userToken = this.localStorage.getItem('reviewToken');
    if (userToken) {
      return userToken;
    }
    return '';
  }

  setAuthorizationToken(token: string): void {
    this.localStorage.setItem('reviewToken', token)
  }


  logout() {
    this.localStorage.removeItem('reviewToken');
    this.localStorage.removeItem('socialUser');
    this.localStorage.removeItem('provider');
    this.router.navigate(['/home']);
    // this.httpService.postReq(`/api/auth/logout`).subscribe(
    //   resp => {
    //   },
    //   err => {
    //     console.log("error", err);
    //   }
    // )
  }



  // signOut(): void {
  //   this.socialAuthService.signOut();
  // }


  // Twitter login
  twitterLogin(){

  }

}
