import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SimpleNotificationsModule } from 'angular2-notifications';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { UnauthorizationInterceptor } from './shared/interceptors/unauthorization.interceptor';
import { HttpService } from './shared/services/http.service';

import { ReactiveFormsModule } from '@angular/forms';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TwitterService } from './shared/services/twitter.service';
import { SignInSuccessService } from './shared/services/sign-in-success.service';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    SocialLoginModule,
    SimpleNotificationsModule.forRoot({
      position:["top", "right" ],
      timeOut: 4000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true,
      clickIconToClose: true,
      // preventDuplicates: true
    }),
    PaginationModule.forRoot(),
    ReactiveFormsModule,
    NgxSliderModule,
  ],
  providers: [
    HttpService,
    FormBuilder,
    TwitterService ,
    SignInSuccessService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizationInterceptor, multi: true },
    SocialLoginModule,
    ReactiveFormsModule,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
            {
              id: FacebookLoginProvider.PROVIDER_ID,
              provider: new FacebookLoginProvider('446118530286292')
            },
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                // '607057377841-9hh9o10uuq8ditup7ud91r55hlkvhp2f.apps.googleusercontent.com'
                '423275834573-rnbh9eip15fji2os01p8crsa094aabvj.apps.googleusercontent.com' // in Review System app in google console
              )
            }
        ],
        onError: (err) => {
          console.error("Error Here: ",err);
        }
      } as SocialAuthServiceConfig,
    }   
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }


// ng g m shared/shared --routing
// ng g c shared/shared/components/shared --module shared
