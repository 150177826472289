import { HttpClient, HttpClientJsonpModule, HttpHeaders} from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
// import {Headers, Http, RequestOptions, URLSearchParams} from '@angular/http';
// import {Headers, HttpC, RequestOptions, URLSearchParams} from '@angular/http';
import 'rxjs/add/operator/toPromise';
// import '../../requirets/require';
import * as CryptoJS from 'crypto-js';
// import {JsonpModule, Jsonp, Response} from '@angular/http';
// let CryptoJS = require('crypto-js');


const CONSUMER_KEY = "4Y9ZDb2MfuZgMss3hZ3gCJ3Mn";
const CONSUMER_SECRET = "IoCGHt9hQsAL46NQ5Nrap6qnl1gGv2PvqXb1f2YkozZROv2tXY";
// const CONSUMER_KEY = "xvz1evFS4wEEPTGEFPHBog";
// const CONSUMER_SECRET = "kAcSOqF21Fu85e7zjz7ZN2U4ZRhfV3WpwPAoE3Z7kBw";

@Injectable()
export class TwitterService {

  public data: JSON;
  public allData: string;
  public token: string;
  public verifier: string;
  public trends: any;
  public suggestedUsers: any;
  public tweeted: any;
  public userInfo: any;
  public arrOfPosts: Array<object>;
  public loadCount = 1;
  // URL to web api
  constructor(private http: HttpClient) {
    console.log(CONSUMER_KEY);
  }

  static getQueryParamsFromUrl(url: string): Array<Object> {
    var quesInd = url.indexOf('?');
    if (quesInd == -1) return [];
    var arr = url.substr(quesInd + 1).split('&');
    var newArr: any = [];
    arr.forEach(function (item) {
      var u: any = {};
      var t = item.split('=');
      u['key'] = t[0];
      u['value'] = t[1];
      newArr.push(u);
    });
    console.log("This is my Query Array", newArr);
    return newArr;
  }

  onSignIn(): Promise<any> {
    let twitterCallbackUrl = 'http://localhost:4200/callback';
    // let twitterCallbackUrl = 'https%3A%2F%2Freview-system.ademo.net%2Fhome';
    let url = "https://api.twitter.com/oauth/request_token";
    let method = "POST";
    let oauth_consumer_key = CONSUMER_KEY;
    let oauth_nonce = TwitterService.getNonce(32);
    let oauth_signature_method = "HMAC-SHA1";
    let oauth_timestamp = Math.floor(Date.now() / 1000).toString();
    let oauth_version = "1.0";
    let parameterString = TwitterService.getSignatureString([
      { key: "oauth_callback", value: twitterCallbackUrl },
      { key: "oauth_consumer_key", value: oauth_consumer_key },
      { key: "oauth_nonce", value: oauth_nonce },
      { key: "oauth_signature_method", value: "HMAC-SHA1" },
      { key: "oauth_timestamp", value: oauth_timestamp },
      { key: "oauth_version", value: "1.0" },
    ]);



    let shaKey = encodeURIComponent(CONSUMER_SECRET) + "&"; //Consumer Key Secret
    let final_string = method + "&" + encodeURIComponent(url) + "&" + encodeURIComponent(parameterString);
    console.log("Parameter String:", parameterString);
    console.log("finalString:", final_string);
    console.log("shakey:", shaKey);
    let s = CryptoJS.HmacSHA1(final_string, shaKey);
    let oauth_signature = CryptoJS.enc.Base64.stringify(s);
    console.log(oauth_signature);
    let authValue = TwitterService.getAuthValue([
      { key: "oauth_callback", value: twitterCallbackUrl },
      { key: "oauth_consumer_key", value: oauth_consumer_key },
      { key: "oauth_nonce", value: oauth_nonce },
      { key: "oauth_signature", value: oauth_signature },
      { key: "oauth_signature_method", value: "HMAC-SHA1" },
      { key: "oauth_timestamp", value: oauth_timestamp },
      { key: "oauth_version", value: "1.0" },
    ]);


    let headers = new HttpHeaders({
      'Authorization': authValue,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS"
    });
    //console.log(authValue,url);
    //console.log(http);
    //console.log(oauth_nonce.length);
    console.log("headers: " , authValue)
    return this.http.post(url, '' , {headers : headers}).toPromise()
      .then((response : any) => response)
      .catch(TwitterService.handleError);
    // return this.http.post(url ,'')
  }

  getAccessToken(): Promise<any> {
    let url = "https://api.twitter.com/oauth/access_token";
    let method = "POST";
    let oauth_consumer_key = CONSUMER_KEY;
    let oauth_nonce = TwitterService.getNonce(32);
    let oauth_signature_method = "HMAC-SHA1";
    let oauth_timestamp = Math.floor(Date.now() / 1000).toString();
    let oauth_version = "1.0";
    let parameterString = TwitterService.getSignatureString([
      { key: "oauth_consumer_key", value: oauth_consumer_key },
      { key: "oauth_nonce", value: oauth_nonce },
      { key: "oauth_signature_method", value: "HMAC-SHA1" },
      { key: "oauth_timestamp", value: oauth_timestamp },
      { key: "oauth_token", value: this.token },
      { key: "oauth_verifier", value: this.verifier },
      { key: "oauth_version", value: "1.0" },
    ]);

    let shaKey = encodeURIComponent(CONSUMER_SECRET) + "&"; //Consumer Key Secret
    let final_string = method + "&" + encodeURIComponent(url) + "&" + encodeURIComponent(parameterString);
    console.log("Parameter String:", parameterString);
    console.log("finalString:", final_string);
    console.log("shakey:", shaKey);
    let s = CryptoJS.HmacSHA1(final_string, shaKey);
    let oauth_signature = CryptoJS.enc.Base64.stringify(s);
    console.log(oauth_signature);
    let authValue = TwitterService.getAuthValue([
      { key: "oauth_consumer_key", value: oauth_consumer_key },
      { key: "oauth_nonce", value: oauth_nonce },
      { key: "oauth_signature", value: oauth_signature },
      { key: "oauth_signature_method", value: "HMAC-SHA1" },
      { key: "oauth_timestamp", value: oauth_timestamp },
      { key: "oauth_token", value: this.token },
      { key: "oauth_verifier", value: this.verifier },
      { key: "oauth_version", value: "1.0" },
    ]);

    // let headers = new Headers({'Authorization': authValue});
    //console.log(authValue,url);
    //console.log(http);
    //console.log(oauth_nonce.length);
    let headers = new HttpHeaders({
      'Authorization': authValue
    });
    let options = { headers: headers };

    let resArray = [];
    return this.http.post(url, '', options).toPromise()
      .then((response: any) => response)
      .catch(TwitterService.handleError);
  }


  //any used here is an Object of format {key,value}
  static getSignatureString(arr: Array<any>): string {
    var result = "";
    let l = arr.length;
    for (let i: number = 0; i < l; i++) {
      console.log(arr[i].key, arr[i].value);
      result += encodeURIComponent(arr[i].key) + '=' + encodeURIComponent(arr[i].value);
      if (i != l - 1) {
        result += '&';
      }
    }
    return result;
  }

  static getAuthValue(arr: Array<any>): string {
    var result = "OAuth ";
    let l = arr.length;
    for (let i: number = 0; i < l; i++) {
      result += encodeURIComponent(arr[i].key) + '="' + encodeURIComponent(arr[i].value) + '"';
      if (i != l - 1) {
        result += ', ';
      }
    }
    return result;
  }

  static getNonce(numChars: any): string {
    var st = "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz";
    var l = st.length;
    var result = "";

    for (var i = 0; i < numChars; i++) {
      var randomIndex = Math.floor(Math.random() * l);
      result = result + st.charAt(randomIndex);
    }
    return result;
  }

  static saveToken(response: any) {
    let temp = response["_body"];
    let iA = temp.indexOf('&');
    return temp.substr(0, iA);
  }

  static handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }


}


