import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  { path: 'search-for-crew', loadChildren: () => import('./pages/search-for-captain/search-for-captain.module').then(m => m.SearchForCaptainModule) },

  { path: 'yacht-details/:yachtId', loadChildren: () => import('./modals/yacht/yacht.module').then(m => m.YachtModule) },

  { path: 'yacht-rate', loadChildren: () => import('./modals/yacht-review-edit/yacht-review-edit.module').then(m => m.YachtReviewEditModule) },

  { path: 'yacht-all-ratings/:yachtId', loadChildren: () => import('./pages/yacht-all-reviews/yacht-all-reviews.module').then(m => m.YachtAllReviewsModule) },

  { path: 'crew-rate', loadChildren: () => import('./modals/captain-review-edit/captain-review-edit.module').then(m => m.CaptainReviewEditModule) },

  { path: 'crew-all-ratings/:crewId', loadChildren: () => import('./pages/crew-all-reviews/crew-all-reviews.module').then(m => m.CrewAllReviewsModule) },

  { path: 'search-for-yacht', loadChildren: () => import('./pages/search-for-yacht/search-for-yacht.module').then(m => m.SearchForYachtModule) },

  // { path: 'crew-view/:yachtId', loadChildren: () => import('./pages/crew-view/crew-view.module').then(m => m.CrewViewModule) },

  // { path: 'captain-view', loadChildren: () => import('./pages/captain-view/captain-view.module').then(m => m.CaptainViewModule) },

  { path: 'my-reviews', loadChildren: () => import('./pages/my-reviews/reviews.module').then(m => m.ReviewsModule), canLoad: [AuthGuard] },

  { path: 'crews', loadChildren: () => import('./pages/crew/crew.module').then(m => m.CrewModule), canLoad: [AuthGuard] },

  // this module is used to create or edit a crew member
  { path: 'crew', loadChildren: () => import('./pages/new-crew/new-crew.module').then(m => m.NewCrewModule), canLoad: [AuthGuard] },

  { path: 'yachts', loadChildren: () => import('./pages/yachts/yachts.module').then(m => m.YachtsModule), canLoad: [AuthGuard] },

  { path: 'yacht', loadChildren: () => import('./pages/new-yacht/new-yacht.module').then(m => m.NewYachtModule), canLoad: [AuthGuard] },

  { path: 'crew-member/:crewId', loadChildren: () => import('./pages/captain/captain.module').then(m => m.CaptainModule) },

  // { path: 'new-captain', loadChildren: () => import('./pages/new-captain/new-captain.module').then(m => m.NewCaptainModule) },

  { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule), canLoad: [AuthGuard] },

  // { path: 'yacht-view', loadChildren: () => import('./pages/yacht-view/yacht-view.module').then(m => m.YachtViewModule) },

  { path: 'yacht-crew/:yachtId', loadChildren: () => import('./pages/search-for/search-for.module').then(m => m.SearchForModule) },

  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },

  { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },

  { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },

  { path: 'complete-your-profile', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },

  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },

  { path: 'content', loadChildren: () => import('./pages/privacy-terms/privacy-terms.module').then(m => m.PrivacyTermsModule) },

  { path: '', redirectTo: 'home', pathMatch: 'full' },

  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
