import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorageService } from '../services/webApis/local-storage.service';

@Injectable()
export class UnauthorizationInterceptor implements HttpInterceptor {

  constructor(private router: Router, private localStorage: LocalStorageService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(tap(resp => {}, 
      err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 || err.status === 403) {
            // 403 error might be a result of token expiration so remove it from localstorage then redirect the user to login page
            this.localStorage.removeItem('reviewToken');
            this.router.navigate(['login']);
          }
        }
    }))

  }
}
