import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(requestToHandle: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // Getting token form local storage
      const token = localStorage.getItem('reviewToken');
      if(token){
        const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        const authReq = requestToHandle.clone({ headers });
        return next.handle(authReq);
      }
      return next.handle(requestToHandle);

  }
}
