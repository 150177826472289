import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  allSetting: any;
  $callApi = new BehaviorSubject({});
  getSettingSuccess : boolean = false;

  constructor(private http: HttpService) {
  }
  getSettings() {
    this.http.getReq(`/setting`).subscribe(res => {
      this.allSetting = res?.data;
      this.$callApi.next(true);
    }, err => {
      this.$callApi.next(false);
    })
  }
  
}
