import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { HttpService } from '../services/http.service';
import { LocalStorageService } from '../services/webApis/local-storage.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private router: Router,
    private authService: AuthService,
    private localStorage : LocalStorageService,
    private http : HttpService) {}
  // checks if the user is authenticated to allow his access to most of the routes
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean { // parameters are not used
    if (!this.authService.getAuthorizationToken()) {
      this.router.navigate(['/login']);
      return false;
    }  else {
      return true;
    }
  }
  canLoad(route: Route, segments: UrlSegment[]): boolean {
    if (!this.authService.getAuthorizationToken()) {
      this.router.navigate(['/login']);
      return false;
    }  else {
      this.getUserData();
      return true;
    }
  }

  getUserData() {
    if (this.localStorage.getItem("socialUser")) {
      let user = JSON.parse(this.localStorage.getItem("socialUser") || '');
      if(!user?.fisrtName ||!user?.lastName|| !user?.phone){
        this.router.navigate(["/complete-your-profile"]);
      }
    }
  }
}


